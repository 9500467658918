@import url(https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap);
.wallet-adapter-button {
    background-color: #404144;
    border: none;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-family: 'Inter', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
}

.wallet-adapter-button-trigger {
    background-color: #4e44ce;
}

.wallet-adapter-button:not([disabled]):focus-visible {
    outline-color: white;
}

.wallet-adapter-button:not([disabled]):hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0 0);
}

.wallet-adapter-button[disabled] {
    background: #404144;
    color: #999;
    cursor: not-allowed;
}

.wallet-adapter-button-end-icon,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon img {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 24px;
    height: 24px;
}

.wallet-adapter-button-end-icon {
    margin-left: 8px;
}

.wallet-adapter-button-start-icon {
    margin-right: 8px;
}

.wallet-adapter-collapse {
    width: 100%;
}

.wallet-adapter-dropdown {
    position: relative;
    display: inline-block;
}

.wallet-adapter-dropdown-list {
    position: absolute;
    z-index: 99;
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 10px;
    padding: 10px;
    top: 100%;
    right: 0;
    margin: 0;
    list-style: none;
    background: #2c2d30;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease, visibility 200ms, -webkit-transform 200ms ease;
    transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
    transition: opacity 200ms ease, transform 200ms ease, visibility 200ms, -webkit-transform 200ms ease;
}

.wallet-adapter-dropdown-list-active {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
    background: #404144;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    height: 37px;
    color: white;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0 0);
}

.wallet-adapter-modal-collapse-button svg {
    -webkit-align-self: center;
            align-self: center;
    fill: #999;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: -webkit-transform ease-in 150ms;
    transition: transform ease-in 150ms;
    transition: transform ease-in 150ms, -webkit-transform ease-in 150ms;
}

.wallet-adapter-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity linear 150ms;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
    opacity: 1;
}

.wallet-adapter-modal-button-close {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    cursor: pointer;
    background: none;
    border: none;
    border-radius: 6px;
}

.wallet-adapter-modal-button-close:focus-visible {
    outline-color: white;
}

.wallet-adapter-modal-button-close svg {
    fill: #777;
    transition: fill 200ms ease 0s;
}

.wallet-adapter-modal-button-close:hover svg {
    fill: #fff;
}

.wallet-adapter-modal-logo {
    max-width: 100%;
}

.wallet-adapter-modal-logo-wrapper {
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background: #141515;
    margin-bottom: 10px;
    padding: 12px;
}

.wallet-adapter-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.wallet-adapter-modal-container {
    display: -webkit-flex;
    display: flex;
    margin: 3rem;
    min-height: calc(100vh - 6rem); /* 100vh - 2 * margin */
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

@media (max-width: 480px) {
    .wallet-adapter-modal-container {
        margin: 1rem;
        min-height: calc(100vh - 2rem); /* 100vh - 2 * margin */
    }
}

.wallet-adapter-modal-wrapper {
    box-sizing: border-box;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    z-index: 1050;
    max-width: 400px;
    border-radius: 10px;
    background: #2c2d30;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
    font-family: 'Inter', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 40px 20px 20px;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
    width: 100%;
}

.wallet-adapter-modal-wrapper-no-logo {
    padding-top: 30px;
}

.wallet-adapter-modal-title {
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;
    margin-bottom: 27px;
    margin-top: 0;
    width: 100%;
    text-align: center;
    color: #ffffff;
}

@media (max-width: 374px) {
    .wallet-adapter-modal-title {
        font-size: 26px;
    }
}

.wallet-adapter-modal-list {
    margin: 0 0 12px;
    padding: 0;
    width: 100%;
    list-style: none;
}

.wallet-adapter-modal-list li:not(:first-of-type) {
    margin-top: 12px;
}

